import { Card, Col, ListGroup, Row } from "react-bootstrap";
import axios from "axios";
import vs from '../../images/profile/payment.png';
import {Link} from 'react-router-dom';
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const Footer = () => {
  const { t } = useTranslation();
  var d = new Date();
  return (
    <div className="footer m-4 text-black">


        <footer className="mobilefutshow">
            <div className="footer-nav-wrapper" style={{ background: '#020202'}}>

                <Link className="footer-nav-single" to={"/"}>
                    <div className="menu-wrapper">
                        <i class="fa fa-home mb-1" style={{ color: "#07badd", fontSize : "18px" }}></i>
                        <span className="nav-text">Home</span>
                    </div>
                </Link>

                <Link className="footer-nav-single" to={"/basic-activation"}>
                    <div className="menu-wrapper">
                        <i class="fa fa-cube mb-1" style={{ color: "#07badd", fontSize : "18px" }}></i>
                        <span className="nav-text">Activation</span>
                    </div>
                </Link>
                <Link className="footer-nav-single" to={"/deposit"}>
                    <div className="menu-wrapper">
                        <i class="fa fa-credit-card fa-fw mb-1" style={{ color: "#07badd", fontSize : "18px" }}></i>
                        <span className="nav-text">Deposit</span>
                    </div>
                </Link>

              <Link className="footer-nav-single" to={"/app-profile"}>
                  <div className="menu-wrapper">
                      <i class="fa fa-user-circle mb-1" style={{ color: "#07badd", fontSize : "18px" }}></i>
                      <span className="nav-text">Profile</span>
                  </div>
              </Link>

            </div>
        </footer>


    </div>
  );
};

export default Footer;
