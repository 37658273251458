import React, { Fragment,useState,useEffect } from "react";
import { Form } from "react-bootstrap";
import PageTitle from "../../../layouts/PageTitle";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

const Checkout = () => {


const [depositInput, setDepositInput] = useState({
  product_id: '',
  password: '',
  error_list: [],
});
const handleInput = (e) => {
  e.persist();
  setDepositInput({...depositInput, [e.target.name]: e.target.value });
}

const [selectedpackege, setSelectedPpackeg] = useState('');
const selectedCurrency = localStorage.getItem('selectedCurrency');
const auth_token = localStorage.getItem('auth_token');
const history = useHistory();
const [loading, setLoading] = useState(false);

  const [user, setUser] = useState([]);
    useEffect(()=>{
      const auth_token = localStorage.getItem('auth_token');
      const selectedCurrency = localStorage.getItem('selectedCurrency');
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
            currency: selectedCurrency,
        }
      if (auth_token !== null) {
         axios.post('/apps-profile',data)
         .then(response=>{
           setUser(response.data)
           console.log(response.data.active_status)
           
           })
           .catch(error=>{
             console.log(error)
         })
      }

    },[])

  let active_status;
  if (user == "") {
    console.log(user);
  }else{
    active_status=user.data.active_status;
    if(active_status == 1){
      swal("error","Already Activated your Account !","error");
      history.push('/');
    }

  }


 const [income, setIncome] = useState([]);
 const [selectedPackageId, setSelectedPackageId] = useState(null);
 const [selectedPackageName, setSelectedPackageName] = useState('');
 const [selectedPackamount, setSelectedPackamount] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post('/product-package', {
                    key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
                    currency: selectedCurrency,
                });
                setIncome(response.data.data);
            } catch (error) {
                console.error(error);
            }
        };

        if (auth_token !== null) {
            fetchData();
        }
    }, [auth_token, selectedCurrency]);

    const handlePackageSelection = (packageId, packamount, packageName) => {
        setSelectedPackageId(packageId);
        setSelectedPackamount(packamount);
        setSelectedPackageName(packageName);
    };





const depositSubmit = (e) => {
  e.preventDefault();
  const auth_token = localStorage.getItem('auth_token');
  const data = {
      p_id: selectedPackageId,
      amount: selectedPackamount,
      password: depositInput.password,
      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9'
  }
 if (auth_token !== null && !loading) {
  setLoading(true);
 axios.post(`/sub-basic-activation`, data).then(res => {
  setLoading(false);
       if(res.data.status === 'success')
       {
         swal("Success",res.data.message,"success");
         setDepositInput({...depositInput,
           product_id:'',
           password:'',
           
         });
       }
       else if(res.data.status === 'fail')
       {
         swal("Warning",res.data.message,"warning");
       }
       else 
       {
       setDepositInput({...depositInput, error_list: res.data.validation_error});
       }
       setLoading(false);
   });
 }
}

   return (
      <Fragment>
         <div className="row">
            <div className="col-xl-12">
               <div className="card">
                  <div className="card-body">
                     <div className="row">
                        <div className="col-md-4 order-md-2 mb-4">
                           <h4 className="d-flex justify-content-between align-items-center mb-3">
                              <span className="text-muted">Muntaha Membership</span>
                              <span className="badge badge-primary badge-pill">
                                $
                              </span>
                           </h4>
                           <ul className="list-group mb-3">
                              <li className="list-group-item d-flex justify-content-between lh-condensed">
                                 <div>
                                    <h6 className="my-0">Activation</h6>
                                    <small className="text-muted">
                                       Activation To Principal Wallet
                                    </small>
                                 </div>
                                 <span className="text-muted">100%</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between lh-condensed">
                                 <div>
                                    <h6 className="my-0">Packege</h6>
                                 </div>
                                 <span className="text-muted">{selectedPackageName}</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between lh-condensed">
                                 <div>
                                    <h6 className="my-0">Packege Amount</h6>
                                 </div>
                                 <span className="text-muted">$ {selectedPackamount}</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between lh-condensed">
                                 <div>  
                                    <h6 className="my-0">Wallet</h6>
                                    <small className="text-muted">
                                       Deposit Wallet
                                    </small>   
                                 </div>
                              </li>
                           </ul>
                        </div>
                        <div className="col-md-8 order-md-1">
                           <form onSubmit={depositSubmit} className="needs-validation" noValidate="">
                              <div className="row">


                                    <div className="col-xl-12">
                                      <div className="row mydivpadding d-flex justify-content-center">

                                       <h4 className="mb-3">Select Packege  </h4>
                                        {income.map((incomerow, index )=>(
                                            <div className="col-xl-4 col-xxl-4 col-12">
                                              <div style={{ background: "#03b4f6" , color: '#707070'}} className="card booking">
                                                <div  style={{ textAlign: 'center' }}>
                                                <label data-kt-button="true">
                                                    <div style={{marginTop: "20px"}} className="booking-status d-flex align-items-center">
                                                      <div>
                                                        <h4 style={{ maxWidth: '200px' , color: '#707070' }} className="mb-0 font-w600">{incomerow.packege_name}</h4>
                                                      </div>
                                                    </div>
                                                    <div>
                                                      <div className="grid-product__content">
                                                        <p style={{ marginBottom: '0px', color: 'white' }} className="title">Select</p>
                                                        <input
                                                            className="form-check-input border border-gray-500"
                                                            required
                                                            type="radio"
                                                            name="product_id"
                                                            value={incomerow.id}
                                                            checked={selectedPackageId === incomerow.id}
                                                            onChange={() => handlePackageSelection(incomerow.id,incomerow.amount, incomerow.packege_name)}
                                                        />
                                                        <br />
                                                        <br />
                                                      </div>                       
                                                    </div>
                                                 </label>   
                                                </div>
                                              </div>
                                            </div>

                                          ))}

                                      </div>
                                    </div>
{/*

                                 <div className="col-md-6 mb-3">
                                    <label htmlFor="lastName">Amount</label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       placeholder="Amount"
                                       id="lastName"required
                                       name="amount"
                                       onChange={handleInput} 
                                       value={depositInput.amount}
                                       pattern="[0-9]+(\.[0-9]+)?"
                                       readOnly
                                    />
                                       <span className="text-danger">{depositInput.error_list.amount}</span>
                                 </div>
*/}
                                 <div className="col-md-12 mb-3">
                                    <label htmlFor="firstName">
                                       Account Password
                                    </label>
                                    <input
                                       type="password"
                                       className="form-control"
                                       id="firstName"
                                       placeholder="Password"
                                       required
                                       name="password"
                                       onChange={handleInput} 
                                       value={depositInput.password}
                                    />
                                       <span className="text-danger">{depositInput.error_list.password}</span>
                                    
                                 </div>
                              </div>

                              <hr className="mb-4" />
                              <div className="form-check custom-checkbox mb-2">
                                 <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="same-address"
                                    required

                                 />
                                 <label
                                    className="form-check-label"
                                    htmlFor="same-address"
                                 >
                                    Accept treams & condition
                                 </label>
                              </div>
                              <hr className="mb-4" />
                              <button
                                 className="btn btn-warning btn-lg btn-block"
                                 type="submit"
                                 disabled={loading}
                              >
                              {loading ? 'Submitting...' : 'Activation Continue'}
                              </button>

                           </form>


                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default Checkout;
